export const Blogs = [
    {
        id:1,
        name:"Build Website with ReactJS, Styled-components for Scrolling Animations",
        tags:["react","styled-components"],
        date:"5 Jun, 2022",
        imgSrc:"https://res.cloudinary.com/practicaldev/image/fetch/s--sBeV06Xc--/c_imagga_scale,f_auto,fl_progressive,h_420,q_auto,w_1000/https://dev-to-uploads.s3.amazonaws.com/uploads/articles/2yrzy75q4dzibqz6rf82.png",
        link:"https://www.facebook.com/dohung.v/"
    },
    {
        id:2,
        name:"Build Website with ReactJS, Styled-components for Scrolling Animations",
        tags:["react","styled-components"],
        date:"5 Jun, 2022",
        imgSrc:"https://res.cloudinary.com/practicaldev/image/fetch/s--sBeV06Xc--/c_imagga_scale,f_auto,fl_progressive,h_420,q_auto,w_1000/https://dev-to-uploads.s3.amazonaws.com/uploads/articles/2yrzy75q4dzibqz6rf82.png",
        link:"https://www.facebook.com/dohung.v/"
    },
    {
        id:3,
        name:"Build Website with ReactJS, Styled-components for Scrolling Animations",
        tags:["react","styled-components"],
        date:"5 Jun, 2022",
        imgSrc:"https://res.cloudinary.com/practicaldev/image/fetch/s--sBeV06Xc--/c_imagga_scale,f_auto,fl_progressive,h_420,q_auto,w_1000/https://dev-to-uploads.s3.amazonaws.com/uploads/articles/2yrzy75q4dzibqz6rf82.png",
        link:"https://www.facebook.com/dohung.v/"
    },
    {
        id:4,
        name:"Build Website with ReactJS, Styled-components for Scrolling Animations",
        tags:["react","styled-components"],
        date:"5 Jun, 2022",
        imgSrc:"https://res.cloudinary.com/practicaldev/image/fetch/s--sBeV06Xc--/c_imagga_scale,f_auto,fl_progressive,h_420,q_auto,w_1000/https://dev-to-uploads.s3.amazonaws.com/uploads/articles/2yrzy75q4dzibqz6rf82.png",
        link:"https://www.facebook.com/dohung.v/"
    },
    {
        id:5,
        name:"Build Website with ReactJS, Styled-components for Scrolling Animations",
        tags:["react","styled-components"],
        date:"5 Jun, 2022",
        imgSrc:"https://res.cloudinary.com/practicaldev/image/fetch/s--sBeV06Xc--/c_imagga_scale,f_auto,fl_progressive,h_420,q_auto,w_1000/https://dev-to-uploads.s3.amazonaws.com/uploads/articles/2yrzy75q4dzibqz6rf82.png",
        link:"https://www.facebook.com/dohung.v/"
    },
    {
        id:6,
        name:"Build Website with ReactJS, Styled-components for Scrolling Animations",
        tags:["react","styled-components"],
        date:"5 Jun, 2022",
        imgSrc:"https://res.cloudinary.com/practicaldev/image/fetch/s--sBeV06Xc--/c_imagga_scale,f_auto,fl_progressive,h_420,q_auto,w_1000/https://dev-to-uploads.s3.amazonaws.com/uploads/articles/2yrzy75q4dzibqz6rf82.png",
        link:"https://www.facebook.com/dohung.v/"
    },
    {
        id:7,
        name:"Build Website with ReactJS, Styled-components for Scrolling Animations",
        tags:["react","styled-components"],
        date:"5 Jun, 2022",
        imgSrc:"https://res.cloudinary.com/practicaldev/image/fetch/s--sBeV06Xc--/c_imagga_scale,f_auto,fl_progressive,h_420,q_auto,w_1000/https://dev-to-uploads.s3.amazonaws.com/uploads/articles/2yrzy75q4dzibqz6rf82.png",
        link:"https://www.facebook.com/dohung.v/"
    },
    {
        id:8,
        name:"Build Website with ReactJS, Styled-components for Scrolling Animations",
        tags:["react","styled-components"],
        date:"5 Jun, 2022",
        imgSrc:"https://res.cloudinary.com/practicaldev/image/fetch/s--sBeV06Xc--/c_imagga_scale,f_auto,fl_progressive,h_420,q_auto,w_1000/https://dev-to-uploads.s3.amazonaws.com/uploads/articles/2yrzy75q4dzibqz6rf82.png",
        link:"https://www.facebook.com/dohung.v/"
    },
  
] 