export const Work = [
    {
        id:1,
        name:"DoHung Landing Page",
        description:"It is build on top of the React JS, with styledComponents for smooth scrolling animations.",
        tags:["react","styledComponents"],
        
        demo:"https://www.facebook.com/dohung.v/",
        github:"https://github.com/dvhuzg"
    },
    {
        id:2,
        name:"DoHung Landing Page",
        description:"It is build on top of the React JS, with styledComponents for smooth scrolling animations.",
        tags:["react","styledComponents"],
        
        demo:"https://www.facebook.com/dohung.v/",
        github:"https://github.com/dvhuzg"
    },
    {
        id:3,
        name:"DoHung Landing Page",
        description:"It is build on top of the React JS, with styledComponents for smooth scrolling animations.",
        tags:["react","styledComponents"],
        
        demo:"https://www.facebook.com/dohung.v/",
        github:"https://github.com/dvhuzg"
    },
    {
        id:4,
        name:"DoHung Landing Page",
        description:"It is build on top of the React JS, with styledComponents for smooth scrolling animations.",
        tags:["react","styledComponents"],
        
        demo:"https://www.facebook.com/dohung.v/",
        github:"https://github.com/dvhuzg"
    },
    {
        id:5,
        name:"DoHung Landing Page",
        description:"It is build on top of the React JS, with styledComponents for smooth scrolling animations.",
        tags:["react","styledComponents"],
        
        demo:"https://www.facebook.com/dohung.v/",
        github:"https://github.com/dvhuzg"
    },
    {
        id:6,
        name:"DoHung Landing Page",
        description:"It is build on top of the React JS, with styledComponents for smooth scrolling animations.",
        tags:["react","styledComponents"],
        
        demo:"https://www.facebook.com/dohung.v/",
        github:"https://github.com/dvhuzg"
    },
    
    
  
] 